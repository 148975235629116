
import { Component, Vue } from "vue-property-decorator";

import * as ScraperServices from "@/services/ScraperService";
import * as FactcheckRequestServices from "@/services/FactCheckRequestServices";
import * as SeoServices from "@/services/SeoServices";
import * as RssServices from '@/services/RssServices';

@Component({
  components: {
    LogoutDialog: () => import("@/components/dialogs/LogoutDialog.vue"),
  },
})
export default class Navigation extends Vue {
  dialogs = {
    logout: false,
  };

  get admin(): boolean {
    return this.$store.state.user.profile.isAdmin;
  }


  unusedArticleCount = 0
  unhandledRequestCount = 0
  articlesWithNoSeo = 0
  unseenRssItems = 0

  async mounted() {
    const data = await ScraperServices.GetScrapedArticles(10, 0)

    let badgeItems = data.rows.filter(e => !e.used);

    this.unusedArticleCount = badgeItems.length

    const requests = await FactcheckRequestServices.GetRequests()
    this.unhandledRequestCount = requests.filter(e => !e.answered).length

    const articlesWithoutSeo = await SeoServices.GetFactchecksWithoutSeoConfig()
    this.articlesWithNoSeo = articlesWithoutSeo.length

    const electifactsRssFeed = await RssServices.DownloadRssFeed('https://www.electifacts.eu/rss')
    let ids = electifactsRssFeed.rss.channel.item.map((i: any) => i.guid._text)

    const seenItems = localStorage.getItem('rss')
    if (seenItems) {
      let parsed = JSON.parse(seenItems)
      let unseen = ids.filter((e: string) => !parsed.includes(e))
      this.unseenRssItems = unseen.length
    } else {
      this.unseenRssItems = ids.length
    }
  }
}
